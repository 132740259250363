import React from 'react';
import ReactDOM from 'react-dom';
import mapboxgl from 'mapbox-gl';
import Avatar from '@material-ui/core/Avatar';
import { firebase, db, GeoFirestore } from '../firebase/appfirebase';
import { AppBar, Drawer, Typography, Button } from '@material-ui/core';

mapboxgl.accessToken = 'pk.eyJ1Ijoiam9zaHBhcmtpbiIsImEiOiJja2wzMzRycWkwa2kzMm9tbTF0a3B4aGxiIn0.S-h0Wj1lcmoM5vz2k5XSnQ';

let map = null; 
let maplayer = null;


class Map extends React.Component {
    

    initialNotice = `Welcome to the TippingJar!  This site allows you to easily tip your local 
                        artist.  On the map above, select the artist close to you and leave them a tip.
                        It is completely anonymous and safe.  In turn the artist immediately recieves 
                        your tip and any additional comment you would like to leave. `


    /** currency converter  */
    formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
      });
    
      constructor(props) {
        super(props);
        /* initialize state */
        this.state = {
          lng: -79,
          lat: 46,
          zoom: 18,
          range: 10000.0,
          showDrawer: true,
          artistCard: 
            <div id="artist_card">
              <Typography>
                {this.initialNotice}
              </Typography>
              <Button onClick={() => {
                this.setState({'showDrawer': false});
                } 
              }> 
              Select the artist 
              </Button>  
            </div>
        };
      }

      componentDidMount() {
 
        this.setupMap();
    
        var navOptions = {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0
        }
    
       navigator.geolocation.getCurrentPosition(this.updatePosition, this.positionError, navOptions);
       
      }

      setupMap = () => {

        this.map = new mapboxgl.Map({
          container: this.mapContainer,
          style: 'mapbox://styles/mapbox/streets-v11',
          center: [this.state.lng, this.state.lat],
          zoom: this.state.zoom
        });
    
        this.setupMouseClick();
        this.displayIntroDrawer();
      }

      positionError(err) {
        console.warn(`ERROR(${err.code}): ${err.message}`);
      }
    
      updatePosition = (position) => {
        this.setState({
          lng: position.coords.longitude,
          lat: position.coords.latitude
        }); 
    
        this.map.flyTo( {
            center: [this.state.lng, this.state.lat]
          });
        this.getArtistsAroundMe()
      }
    
      getArtistsAroundMe = () => {
        console.log('around me query goes here...');
        // initialize db
        if( GeoFirestore == null )
        {
           console.log("GeoFirestore is null");
           console.log( GeoFirestore );
        }
        else {
          console.log("Geofirestore not null");
        }
        const geocollection = GeoFirestore.collection('Artists');
        console.log("latitude:" + this.state.lat);
        console.log("latitude:" + this.state.lng);
     
        var features = []
    
        const query = geocollection.near({ center: new firebase.firestore.GeoPoint( this.state.lat, this.state.lng ),
                                                  radius: this.state.range});
        
        query.get().then((value) => {
    
          features = this.processGeoQuery(value);
    
        }).then((e) => { 
    
          var json = this.buildFeatureLayer(features);
          if(json == null) return;
    
          console.log(json);
          this.map.addSource( 'artists', { 'type': 'geojson', 'data': json  } );
          this.setupMapLayer();
          //this.maplayer = this.map.featureLayer().
          //                          addTo(this.map);
          // https://docs.mapbox.com/mapbox-gl-js/example/popup-on-click/
          // https://javascript.plainenglish.io/creating-custom-pins-with-react-mapbox-8b2c91a8659f
          // https://docs.mapbox.com/mapbox.js/example/v1.0.0/change-marker-color-click/
        });
        
      }
      
      setupMapLayer = () => {
        this.map.addLayer( {
          'id': 'Artists',
          'type': 'symbol',
          'source': 'artists',
          'layout': {
            // 'icon-image': '{icon}}-15', 
            'icon-image': 'rocket-15',
            'icon-allow-overlap': true
            }
         });
        
      
      }
    
    //
    // pop up new drawer
    displayIntroDrawer = () => {
        this.toggleDrawer(true);
    }
    
    // enable the mouse clicking
    setupMouseClick = () => {

        this.map.processMouseClick = (e) => {
            var coordinates = e.features[0].geometry.coordinates.slice();
            var name = e.features[0].properties.name;
            var saying = e.features[0].properties.saying;
            var button1 = e.features[0].properties.button1;
            var button2 = e.features[0].properties.button2;
            var button3 = e.features[0].properties.button3;
            console.log(e.features[0].properties);
            console.log(saying);
            console.log(button1);
            console.log(button2);
            console.log(button3);

            var artist_card = 
                <div id="artist_card">
                <div class="flex">
                    <Avatar>{ name.charAt(0) }</Avatar>
                    <Typography variant="h5">
                    &nbsp;&nbsp;{ name }
                    </Typography>
                </div>
                <Typography>
                        { saying }
                </Typography>
                    <div id="donate_buttons">
                    <Typography>
                        Select Tip:
                        <Button onClick={() => {
                        this.makeDonation(button1);
                        this.setState({'showDrawer': false});
                        } 
                        }> 
                        { this.formattedNumber(button1) }
                        </Button>
                        <Button onClick={() => {
                            this.makeDonation(button2);
                            this.setState({'showDrawer': false});
                            } 
                        }> 
                        { this.formattedNumber(button2) }
                        </Button>
                        <Button onClick={() => {
                            this.makeDonation(button3);
                            this.setState({'showDrawer': false});
                            } 
                        }> 
                        { this.formattedNumber(button3) } 
                        </Button>
                        <Button onClick={() => {
                            this.setState({'showDrawer': false});
                            } 
                        }> 
                        Other
                        </Button>
                        </Typography>
                    </div>
                </div>

                this.setState( { 'showDrawer': true,
                                artistCard: artist_card
                            } );
        

        };


        // clicking
        this.map.on('click', 'Artists', function (e) {
            e.target.processMouseClick(e);
        });
    }
    
    formattedNumber = (nmbr) => {
        return this.formatter.format(nmbr);
    }

    makeDonation = (buttonValue) => {
        console.log('button pressed' + buttonValue);
    }

     
      // process the results of the query
    processGeoQuery = (result) => {
        var features = [];
        // All GeoDocument returned by GeoQuery, like the GeoDocument added above
        console.log(result.docs);
        result.docs.forEach((doc) => {
            // add in 
            console.log(doc.data());
            /*
            var marker = new mapboxgl.Marker()
                            .setLngLat([doc.data().coordinates.longitude, doc.data().coordinates.latitude])
                            .addTo(this.map);
            */
            var feature = {
            type: 'Feature',
            geometry: {
                type: 'Point',
                coordinates: [doc.data().coordinates.longitude, doc.data().coordinates.latitude]
            },
            properties: {
                name: doc.data().name,
                'saying': doc.data().saying,
                button1: doc.data().button1,
                button2: doc.data().button2,
                button3: doc.data().button3
            }
            };
            features.push( feature );
        });
        return features;
    }
    
    buildFeatureLayer = (featureCollection) => {
        if( featureCollection.length == 0 ) return null;
        var geoJson = {
            type: 'FeatureCollection',
            features: featureCollection
        };
        console.log('geoJson object:');
        console.log(geoJson);
        return geoJson; 
    }

    
    toggleDrawer = (open) => (event) => {
    
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            console.log("Drawer open:" + open)
            return;
        }
        console.log('toggle drawer');
        this.setState( { 'showDrawer': open } );
    
    }

    
    
    
    render() {
    
        return (
            <div>
            <AppBar position="static" color="transparent" >
            
            </AppBar>

            <div ref={el => this.mapContainer = el} className="mapContainer">
            <div id="bottom" class="sidebar flex-center right collapsed">
                <div id="UserCard" class="sidebar-content rounded-rect flex-center">
                </div>
                </div>
            </div>
            { /* this is the intro drawer */ }
            <Drawer
                anchor={"bottom"}
                open={this.state.showDrawer}
                onClose={this.toggleDrawer(false)}
                onOpen={this.toggleDrawer(true)}
                >
                { this.state.artistCard } 
            </Drawer>
            
            </div>
        );
    }

}

export default Map;
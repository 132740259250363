import React from 'react';
import { Button, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';

function Signin() {

    return (
        <div>
            <Typography>
                Login Stuff
            </Typography>
            <Link to="/signin">
                <Button>
                    Do it now
                </Button>
            </Link>
        </div>
    )
}

export default Signin
import React from 'react';
import { Button, Typography, Drawer } from '@material-ui/core';
import { Link } from 'react-router-dom';


class FirstPage extends React.Component {

    render() {
        return (
            <div id="firstpage">
                <Typography variant="h5">
                    TippingJar.net
                </Typography>
                <Typography>
                    We allow you to easily tip individuals or artists around
                    you. You will be prompted to allow location search.  This 
                    is so that we easily find the artists around you.<br />
                    Your tip is anonymous an no personal information is stored
                    unless you want it to.
                </Typography>
                <Link to="/map">
                    <Button>Ready to tip?</Button>
                </Link>
                <Typography>
                    <strong>Remember to allow location</strong>
                </Typography>
              
                    <Drawer
                        anchor={'bottom'}
                        variant={'permanent'}                    
                        open={true}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                        >
                        <Typography>
                            Are you an artist and want to sign up?  
                        </Typography>
                        <Link to="/signup">
                            <button>Click Here</button>
                        </Link>
                    </Drawer>
              
            </div>
        )
    }

}

export default FirstPage;
import './App.css';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { FirstPage, Map, Signup, Signin } from './components';

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route path="/" exact component = {() => <FirstPage />} />
          <Route path="/map" exact component = {() => <Map />} />
          <Route path="/signup" exact component = {() => <Signup />} />
          <Route path="/signin" exact component = {() => <Signin />} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;

import firebase from "firebase";
import * as geofirestore from 'geofirestore';

const firebaseApp = firebase.initializeApp({
    apiKey: "AIzaSyBrPD_ilcAojuanHqb2QZuR0Loz7YqGZng",
    authDomain: "tippingjar-baa49.firebaseapp.com",
    databaseURL: "https://tippingjar-baa49.firebaseio.com",
    projectId: "tippingjar-baa49",
    storageBucket: "tippingjar-baa49.appspot.com",
    messagingSenderId: "890979892056",
    appId: "1:890979892056:web:4cedb3b22dff1ff60e032d",
    measurementId: "G-1MD13D0HXH"
});

const db = firebaseApp.firestore();

const GeoFirestore = geofirestore.initializeApp(db);

export { db, firebase, GeoFirestore };
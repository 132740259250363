import React from 'react';
import ReactDOM from 'react-dom';
// import { Map } from './components';
import App from './App';
  
ReactDOM.render(
  < App />,
  document.getElementById('map')
);


